import React, { Component } from 'react';
import { ReactComponent as Desna } from './img/desna.svg';
import { ReactComponent as Leva } from './img/leva.svg';

import Show0 from './show/Show0.jsx';
import Show1 from './show/Show1.jsx';
import Show2 from './show/Show2.jsx';
import Show3 from './show/Show3.jsx';
import Show4 from './show/Show4.jsx';
import Show5 from './show/Show5.jsx';


class MyWay extends Component {
  /*
  constructor (props) {
  super(props);
  }
*/
  state = {
     show: 1,
  };



  componentDidMount() {
  //  console.log(this.props.ijezik);
  }

  previous = () => {
    let sh = this.state.show;
    let prethodni = this.state.show === 1 ? 6 : --sh;

    this.setState({
      show: prethodni
    });

  };

  next = () => {
    let sh = this.state.show;
    let naredni = this.state.show === 6 ? 1 : ++sh;

    this.setState({
      show: naredni
    });

  };

  render() {


    return (

        <div className='zastrelice'>
        {this.props.imob === false ?
        <div className='levdug' onClick={this.previous}>
        <Leva />
        </div>
        :
        <div className='levdugmob' onClick={this.previous}>
        <Leva />
        </div>
        }
{
        this.state.show === 1 ?
        <Show0 ijezik={this.props.ijezik} imob={this.props.imob}/>
        :
        this.state.show === 2 ?
        <Show1 ijezik={this.props.ijezik} imob={this.props.imob} />
        :
        this.state.show === 3 ?
        <Show2  ijezik={this.props.ijezik} imob={this.props.imob} />
        :
        this.state.show === 4 ?
        <Show3 ijezik={this.props.ijezik} imob={this.props.imob} />
        :
        this.state.show === 5 ?
        <Show4 ijezik={this.props.ijezik} imob={this.props.imob} />
        :
        this.state.show === 6 ?
        <Show5  ijezik={this.props.ijezik} imob={this.props.imob} />
        :
        ''
}
        {this.props.imob === false ?
        <div className='desdug' onClick={this.next}>
        <Desna />
        </div>
        :
        <div className='desdugmob' onClick={this.next}>
        <Desna />
        </div>
        }
        </div>

      );
  }
}

export default MyWay;

import React, { Component } from 'react';
import { Icon } from 'antd';
import VideoPlayer from '../video/vp.js'
import 'video.js/dist/video-js.css';






class Show5 extends Component {

  render() {

    var videoJsOptions;


    this.props.ijezik === 0 ?
        videoJsOptions = {
          autoplay: false,
          controls: true,
          sources: [{
            src: "https://konsoft.rs/video/tehno.mp4",
            type: "video/mp4"
          }]
        }
    :
        videoJsOptions = {
          autoplay: false,
          controls: true,
          sources: [{
            src: "https://konsoft.rs/video/tehno-e.mp4",
            type: "video/mp4"
          }]
        }




    return (
        <div className={this.props.imob === true ? 'sredinamob' : 'sredina'}>
{
    this.props.ijezik === 0 ?
          <>
            <div className={this.props.imob === true ? 'prezamob' : 'preza'}>5/5 - Upravljanje tehnologijama</div>
            <div className={this.props.imob === true ? 'novprezmob' : 'novprez'}>
                    <div className={this.props.imob === true ? 'show-problemmob' : 'show-problem'}>
                    <h4><b>Buck Lighting</b>, Beograd</h4>
                    <p>
                    Tehnologije proizvodnje su po starom sistemu pisane u Excel fajlovima i čuvane na fajl serveru. Kada bi zaposleni Buck-a želeo da proveri neki tehnološki postupak - on je tražio na fajl serveru dokument (Excel fajl), i naravno dešavalo se da otvori fajl u kojem je stara tehnologija, neka prethodna revizija. Tehnološki postupci sadrže i električne šeme i uputstva u pdf-u, fotografije, video snimke procesa proizvodnje. Bili su to Excel fajlovi sa npr. 50 linkova ka fotografijama, 2-3 linka ka pdf-ovima i 4-5 linkova ka video klipovima.
                    </p>
                    <p className='pnote'>
                    <Icon type='warning' /> U priloženom videu aplikacija je konektovana na bazu sa izmišljenim podacima.
                    </p>
                    </div>
                    <div className='za-video'>
                    <div className='show-problem-v'>
                    <VideoPlayer { ...videoJsOptions }  />
                    </div>
                    </div>
            </div>
          </>
          :
          <>
            <div className={this.props.imob === true ? 'prezamob' : 'preza'}>5/5 - Technology Management System</div>
            <div className={this.props.imob === true ? 'novprezmob' : 'novprez'}>
                    <div className={this.props.imob === true ? 'show-problemmob' : 'show-problem'}>
                    <h4><b>Buck Lighting</b>, Belgrade</h4>
                    <p>
                    The production technologies used in Buck Lighting were written in Excel files and stored on a file server. If an employee of Buck wanted to check some technological procedure - he had to search for a file on the server, and often he would open some old, not revised, files. Those technological procedures also include electrical schemes and instructions in pdfs, photographs, videos of the production process. They were Excel files with e.g. 50 links to photos, 2-3 links to pdfs and 4-5 links to video clips.
                    </p>
                    <p className='pnote'>
                    <Icon type='warning' />  Application is connected to the database with the fake data in the video.
                    </p>
                    </div>
                    <div className='za-video'>
                    <div className='show-problem-v'>
                    <VideoPlayer { ...videoJsOptions }  />
                    </div>
                    </div>
            </div>
          </>

}

        </div>
      );
  }
}

export default Show5;

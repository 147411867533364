import React, { Component } from 'react';
import { Icon } from 'antd';
import VideoPlayer from '../video/vp.js'
import 'video.js/dist/video-js.css';






class Show2 extends Component {

  render() {
    var videoJsOptions;


    this.props.ijezik === 0 ?
        videoJsOptions = {
          autoplay: false,
          controls: true,
          sources: [{
            src: "https://konsoft.rs/video/tracking.mp4",
            type: "video/mp4"
          }]
        }
    :
        videoJsOptions = {
          autoplay: false,
          controls: true,
          sources: [{
            src: "https://konsoft.rs/video/tracking-e.mp4",
            type: "video/mp4"
          }]
        }




    return (
        <div className={this.props.imob === true ? 'sredinamob' : 'sredina'}>

{    this.props.ijezik === 0 ?
  <>
        <div className={this.props.imob === true ? 'prezamob' : 'preza'}>2/5 - Kontrola prisutnosti i kretanja</div>
            <div className={this.props.imob === true ? 'novprezmob' : 'novprez'}>
                    <div className={this.props.imob === true ? 'show-problemmob' : 'show-problem'}>
                    <h4><b>Aplikacija za obradu podataka kontrole pristupa</b></h4>
                    <p>
                    Aplikacija je izvorno pravljena za sistem kontrole pristupa ROGER/Paradox, ali se može lako prilagoditi bilo kom sličnom sistemu za kontrolu pristupa. Implementirana je na sistemu koji podržava desetak udaljenih lokacija sa više od 1000 zaposlenih. Osnovni smisao aplikacije je da omogući širokom krugu korisnika jednostavan uvid u podatke od interesa poštujući organizacionu hijerarhiju i poverljivost/bezbednost podataka. Omogućena je ažurna i potpuno transparentna evidencija radnog vremena i kontrola ponašanja zaposlenih u štićenom prostoru, bez potrebe angažovanja zaposlenih u sektoru bezbednosti u cilju izveštavanja po zahtevu menadžmenta i slično.
                    </p>
                    <p className='pnote'>
                    <Icon type='warning' /> U priloženom videu aplikacija je konektovana na bazu sa izmišljenim podacima.
                    </p>
                    </div>
                    <div className='za-video'>
                    <div className='show-problem-v'>
                    <VideoPlayer { ...videoJsOptions }  />
                    </div>
                    </div>
            </div>
  </>
:
  <>
        <div className={this.props.imob === true ? 'prezamob' : 'preza'}>2/5 - Attendance and Tracking System</div>
            <div className={this.props.imob === true ? 'novprezmob' : 'novprez'}>
                    <div className={this.props.imob === true ? 'show-problemmob' : 'show-problem'}>
                    <h4><b>Attendance & tracking Web App</b></h4>
                    <p>
                    The application was originally created for an ROGER/Paradox access control system, but can be easily adapted to any similar access control system. It is implemented on a system that supports a dozen remote locations with more than 1000 employees. The basic purpose of the application is to provide a wide range of users with easy insight into the data of interest while respecting the organizational hierarchy and confidentiality / security of the data.Easy attendance control and employees' tracking in the protected areas, without the need of engaging employees of the security sector with the aim of reporting on management demand and the like.
                    </p>
                    <p className='pnote'>
                    <Icon type='warning' />  Application is connected to the database with the fake data in the video.
                    </p>
                    </div>
                    <div className='za-video'>
                    <div className='show-problem-v'>
                    <VideoPlayer { ...videoJsOptions }  />
                    </div>
                    </div>
            </div>
  </>
}
        </div>
      );
  }
}

export default Show2;

import React from 'react';
import videojs from 'video.js';

// izbacio:  data-setup='{"fluid": true}'
export default class VideoPlayer extends React.Component {
  componentDidMount() {
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  render() {
    return (
      <div>
        <div data-vjs-player>
          <video ref={ node => this.videoNode = node } className="video-js vjs-big-play-centered vjs-default-skin vjs-fluid" controls preload="auto" data-setup='{ "responsive": true }'></video>
        </div>
      </div>
    )
  }
}

//vjs-fluid
//vjs-fill
//vjs-big-play-centered

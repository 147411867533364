import React, { Component } from 'react';
import { Icon } from 'antd';
import VideoPlayer from '../video/vp.js'
import 'video.js/dist/video-js.css';






class Show1 extends Component {

  render() {
    var videoJsOptions;


this.props.ijezik === 0 ?
    videoJsOptions = {
      autoplay: false,
      controls: true,
      sources: [{
        src: "https://konsoft.rs/video/mhc-dht.mp4",
        type: "video/mp4"
      }]
    }
:
    videoJsOptions = {
      autoplay: false,
      controls: true,
      sources: [{
        src: "https://konsoft.rs/video/mhc-dht-e.mp4",
        type: "video/mp4"
      }]
    }


    return (
    this.props.ijezik === 0 ?
        <div className={this.props.imob === true ? 'sredinamob' : 'sredina'}>
        <div className={this.props.imob === true ? 'prezamob' : 'preza'}>1/5 - Upravljanje dokumentima</div>
            <div className={this.props.imob === true ? 'novprezmob' : 'novprez'}>
                    <div className={this.props.imob === true ? 'show-problemmob' : 'show-problem'}>
                    <h4><b>Gazprom Neft</b>, Projekat modernizacije Rafinerije nafte Pančevo</h4>
                    <p>
                    Na početku projekta na serveru je bilo svega 12 osnovnih foldera (direktorijuma) za dokumentaciju. Već prve godine ogromna količina dokumentacije je dovela do stvaranja brojnih podfoldera u kojima je često bilo nemoguće snaći se. U pokušajima da se bolje organizuju dokumenti saradnici su često menjali strukturu foldera na serveru, što je dovodilo do gubitka linkova u Excel tabelama - registrima. Da steknete predstavu o obimima - finalna tehnička dokumentacija (tek mali deo ukupne dokumentacije) se sastojala od 107267 dokumenata u 9613 foldera. Ova aplikacija je rešila problem.
                    </p>

                    <p className='pnote'>
                    <Icon type='warning' /> U priloženom videu aplikacija je konektovana na bazu sa izmišljenim podacima.
                    </p>


                    </div>
                    <div className='za-video'>

                    <div className='show-problem-v'>


                    <VideoPlayer { ...videoJsOptions }  />


                    </div>


                    </div>
            </div>
        </div>
    :
    <div className={this.props.imob === true ? 'sredinamob' : 'sredina'}>
    <div className={this.props.imob === true ? 'prezamob' : 'preza'}>1/5 - Document Management System</div>
        <div className={this.props.imob === true ? 'novprezmob' : 'novprez'}>
                <div className={this.props.imob === true ? 'show-problemmob' : 'show-problem'}>
                <h4><b>Gazprom Neft</b>, Pančevo Oil Refinery Project</h4>
                <p>
                At the beginning of this project, there were only 12 main folders for documentation on the server. During the first year of the project, a number of documentation has increased and created numerous subfolders that were often impossible to manage. In many attempts to improve the organization of the documents, project team members frequently changed the folders structure on the server, which resulted in the loss of references in Excel tables/registers. To get an idea of the volume, the final technical documentation (only a small part of the all documentation) consisted of 107267 documents in 9613 folders. This application solved the problem.
                </p>

                <p className='pnote'>
                <Icon type='warning' />  Application is connected to the database with the fake data in the video.
                </p>


                </div>
                <div className='za-video'>

                <div className='show-problem-v'>


                <VideoPlayer { ...videoJsOptions }  />


                </div>


                </div>
        </div>
    </div>
      );
  }
}

export default Show1;

import React, { Component } from 'react';

//import { Tabs, Icon, Button } from 'antd';
import { Divider, Badge, Tag, Button, message } from 'antd';
//import ZnakPitanja from './img/res.svg';

import A01 from './img/a01.svg';
import A02 from './img/a02.svg';
import A03 from './img/a03.svg';
import A04 from './img/blu.svg';
import A05 from './img/a05.svg';
import A06 from './img/a06.svg';
import A07 from './img/a07.svg';
import A08 from './img/a08.svg';
import A09 from './img/a09.svg';
import A10 from './img/a10.svg';

import B01 from './img/b01.svg';
import B02 from './img/b02.svg';
import B03 from './img/b03.svg';
import B04 from './img/blu.svg';
import B05 from './img/b05.svg';
import B06 from './img/b06.svg';
import B07 from './img/b07.svg';
import B08 from './img/b08.svg';
import B09 from './img/b09.svg';
import B10 from './img/b10.svg';

import C01 from './img/c01.svg';
import C02 from './img/c02.svg';
import C03 from './img/c03.svg';
import C04 from './img/blu.svg';
import C05 from './img/c05.svg';
import C06 from './img/c06.svg';
import C07 from './img/c07.svg';
import C08 from './img/c08.svg';
import C09 from './img/c09.svg';
import C10 from './img/c10.svg';

import D01 from './img/d01.svg';
import D02 from './img/d02.svg';
import D03 from './img/d03.svg';
import D04 from './img/blu.svg';
import D05 from './img/d05.svg';
import D06 from './img/d06.svg';
import D07 from './img/d07.svg';
import D08 from './img/d08.svg';
import D09 from './img/d09.svg';
import D10 from './img/d10.svg';

import E01 from './img/e01.svg';
import E02 from './img/e02.svg';
import E03 from './img/e03.svg';
import E04 from './img/blu.svg';
import E05 from './img/e05.svg';
import E06 from './img/e06.svg';
import E07 from './img/e07.svg';
import E08 from './img/e08.svg';
import E09 from './img/e09.svg';
import E10 from './img/e10.svg';

message.config({
  top: 60,
  duration: 4,
});

class TestQ extends Component {
  state = {
    brojka1: false,
    brojka2: false,
    brojka3: false,
    brojka4: false,
    brojka5: false,
    brojka6: false,
    od1: 'default',
    od2: 'default',
    od3: 'default',
    od4: 'default',
    od5: 'default',
    ruka: 1,
    poz1: '',
    poz2: '',
    poz3: '',
    poz4: '',
    poz5: '',
    poz6: '',
    poz7: '',
    poz8: '',
    poz9: '',
    poz10: '',
    tacnih: 0,
    ucitano: false,
    zez: 'Axx'

    //niiz: []
  };

  componentDidMount() {
    this.setState({ ucitano: true, poz1: A01, poz2: A02, poz3: A03, poz4: A04, poz5: A05, poz6: A06, poz7: A07, poz8: A08, poz9: A09, poz10: A10 });
  }

  onUsao = (a) => {
    //console.log('usao u '+a);
    let sta = 'brojka' + a;
    this.setState({ [sta]: true });
  };
  onIzasao = (a) => {
    //console.log('izasao u '+a);
    let sta = 'brojka' + a;
    this.setState({ [sta]: false });
  };
  onKlik = (a) => {
    //console.log('usao u '+a);
    let deljenje = this.state.ruka;
    let sledece = deljenje + 1;
    let pogodaka = this.state.tacnih;

    if (this.state.ruka === 5) {
      if (this.state.tacnih === 4) {
        if (this.state.ucitano === true && this.props.ijezik === 0) {
          message.success('Na prvom sastanku sa nama dobijate od nas čokoladu!');
        } else {
          message.success('At our first meeting you will get chocolate from us!');
        }
      } else {
        if (this.state.ucitano === true && this.props.ijezik === 0) {
          message.warning('Klik na dugme \'Ponovi\' ako želite da pokušate još jednom.');
        } else {
          message.warning('Click the \'Repeat\' button if you want to try again.');
        }
      }

    }
//    this.setState({ [sta]: true });
    switch (sledece) {
      case 2:
        if (a === 1) pogodaka++;
        this.setState({ od1: a === 1 ? 'success' : 'error'});
        this.setState({
          tacnih: pogodaka, ruka: sledece, poz1: B01, poz2: B02, poz3: B03, poz4: B04, poz5: B05, poz6: B06, poz7: B07, poz8: B08, poz9: B09, poz10: B10
        });
        break;
      case 3:
      if (a === 6) pogodaka++;
        this.setState({ od2: a === 6 ? 'success' : 'error'});
        this.setState({
          tacnih: pogodaka, ruka: sledece, poz1: C01, poz2: C02, poz3: C03, poz4: C04, poz5: C05, poz6: C06, poz7: C07, poz8: C08, poz9: C09, poz10: C10
        });
        break;
      case 4:
        if (a === 5) pogodaka++;
        this.setState({ od3: a === 5 ? 'success' : 'error'});
        this.setState({
          tacnih: pogodaka, ruka: sledece, poz1: D01, poz2: D02, poz3: D03, poz4: D04, poz5: D05, poz6: D06, poz7: D07, poz8: D08, poz9: D09, poz10: D10
        });
        break;
      case 5:
        if (a === 2) pogodaka++;
        this.setState({ od4: a === 2 ? 'success' : 'error'});
        this.setState({
          tacnih: pogodaka, ruka: sledece, poz1: E01, poz2: E02, poz3: E03, poz4: E04, poz5: E05, poz6: E06, poz7: E07, poz8: E08, poz9: E09, poz10: E10
        });
        break;
      case 6:
        if (a === 4) pogodaka++;
        this.setState({ od5: a === 4 ? 'success' : 'error'});
        this.setState({
          tacnih: pogodaka, ruka: sledece, poz1: '', poz2: '', poz3: '', poz4: '', poz5: '', poz6: '', poz7: '', poz8: '', poz9: '', poz10: ''
        });
        break;
      default:
    }
  };


  resetujOvo = () => {
    this.setState({
      brojka1: false,
      brojka2: false,
      brojka3: false,
      brojka4: false,
      brojka5: false,
      brojka6: false,
      od1: 'default',
      od2: 'default',
      od3: 'default',
      od4: 'default',
      od5: 'default',
      ruka: 1,
      poz1: A01, poz2: A02, poz3: A03, poz4: A04, poz5: A05, poz6: A06, poz7: A07, poz8: A08, poz9: A09, poz10: A10,
      tacnih: 0,
      o1: 'nema',
      o2: '',
      o3: '',
      o4: ''
    });
  };


  render() {
    return (

      <div className='test'>
      <div className="rezultati">
          <Badge status={this.state.od1} />
          <Badge status={this.state.od2} />
          <Badge status={this.state.od3} />
          <Badge status={this.state.od4} />
          <Badge status={this.state.od5} />
      </div>
      <div className="tstiq iqred">

      {this.state.ruka < 6 ? (
        <>
          <div className="tstkck">
            <img src={this.state.poz1} className='ksvg-v' alt="loading" />
          </div>
          <div className="tstkck">
            <img src={this.state.poz2} className='ksvg-v' alt="loading" />
          </div>
          <div className="tstkck">
            <img src={this.state.poz3} className='ksvg-v' alt="loading" />
          </div>
          <div className="tstkck">
            <img src={this.state.poz4} className='ksvg-v' alt="loading" />
          </div>
        </>)
        : (
          <div className='izvestaj'>
            {this.state.tacnih === 0 && this.props.ijezik === 0 ? 'nema tačnih odgovora' : ''}
            {this.state.tacnih === 0 && this.props.ijezik === 1 ? 'there are no correct answers' : ''}
            {this.state.tacnih === 1 && this.props.ijezik === 1 ? this.state.tacnih+' correct answer' : ''}
            {this.state.tacnih > 1 && this.props.ijezik === 1 ? this.state.tacnih+' correct answers' : ''}
            {this.state.tacnih === 1 && this.props.ijezik === 0 ? this.state.tacnih+' tačan odgovor' : ''}
            {(this.state.tacnih === 2 || this.state.tacnih === 3 || this.state.tacnih === 4) && this.props.ijezik === 0 ? this.state.tacnih+' tačna odgovora' : ''}
            {this.state.tacnih === 5 && this.props.ijezik === 0 ? this.state.tacnih+' tačnih odgovora' : ''}
          </div>
        )
      }


      </div>
<Divider className='detimp'/>
      <div className="tstiq iqred">
      {this.state.ruka < 6 ? (
        <>
            <div className="tstkckm" onClick={() => this.onKlik(1)} onMouseEnter={() => this.onUsao(1)} onMouseLeave={() => this.onIzasao(1)}>
              <img src={this.state.poz5} className='ksvg-m' alt="loading" />
            </div>
            <div className="tstkckm" onClick={() => this.onKlik(2)} onMouseEnter={() => this.onUsao(2)} onMouseLeave={() => this.onIzasao(2)}>
              <img src={this.state.poz6} className='ksvg-m' alt="loading" />
            </div>
            <div className="tstkckm" onClick={() => this.onKlik(3)} onMouseEnter={() => this.onUsao(3)} onMouseLeave={() => this.onIzasao(3)}>
              <img src={this.state.poz7} className='ksvg-m' alt="loading" />
            </div>
            <div className="tstkckm" onClick={() => this.onKlik(4)} onMouseEnter={() => this.onUsao(4)} onMouseLeave={() => this.onIzasao(4)}>
              <img src={this.state.poz8} className='ksvg-m' alt="loading" />
            </div>
            <div className="tstkckm" onClick={() => this.onKlik(5)} onMouseEnter={() => this.onUsao(5)} onMouseLeave={() => this.onIzasao(5)}>
              <img src={this.state.poz9} className='ksvg-m' alt="loading" />
            </div>
            <div className="tstkckm" onClick={() => this.onKlik(6)} onMouseEnter={() => this.onUsao(6)} onMouseLeave={() => this.onIzasao(6)}>
              <img src={this.state.poz10} className='ksvg-m' alt="loading" />
            </div>
          </>)
        :
        (<div className='visina66'>
        <Button type="primary" icon="reload" className='zb' size='small' onClick={this.resetujOvo}>
          {
            this.props.ijezik === 0 ?
                  'Ponovi'
                  :
                  'Repeat'

          }
                </Button>

        </div>)
      }
        </div>

        {this.state.ruka < 6 ? (
            <span className="rezbr iqred">
            <span className={this.state.brojka1 === true ? 'plavo' : 'brojkice'}>1</span>
            <span className={this.state.brojka2 === true ? 'plavo' : 'brojkice'}>2</span>
            <span className={this.state.brojka3 === true ? 'plavo' : 'brojkice'}>3</span>
            <span className={this.state.brojka4 === true ? 'plavo' : 'brojkice'}>4</span>
            <span className={this.state.brojka5 === true ? 'plavo' : 'brojkice'}>5</span>
            <span className={this.state.brojka6 === true ? 'plavo' : 'brojkice'}>6</span>
            </span>)
          :
          <span className='visina21' />
        }
{this.state.ruka < 6 ? (
        <div className='paginacija'>
        <Tag color="#1890ff" className='zbt' >{this.state.ruka} / 5</Tag>
        </div>)
        :
        (<div className='umestopag'>

        </div>)
}


        </div>
      );
  }
}

export default TestQ;

import React, { Component } from 'react';
import { Tabs, Table, Modal, Button, Badge } from 'antd';
import { ReactComponent as Odlasci } from './img/odlasci.svg';
import { ReactComponent as Dolasci } from './img/dolasci.svg';
const axios = require('axios');
const { TabPane } = Tabs;


class Aero extends Component {
  _ucitano = false;
  _intervalId = undefined;
  constructor(props) {
  super(props);
    this.state = {
      ucitano: false,
      activeKey: '2',
      dolasci:[],
      odlasci:[],
      mvisible: false,
      jezik: null
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      jezik: nextProps.ijezik,
    }
  }

  componentDidMount() {
   this._ucitano = true;
  //  if (localStorage.getItem("jezik") === null) {

    const adressa = localStorage.getItem("jezik") === '0' ? 'https://konsoft.rs/2806/dml.php' : 'https://konsoft.rs/2806/dml-e.php';



    const that = this;
    //const huha = this.state.jezik;
      //    axios.get('http://konsoft.rs/2806/dml.php')
          axios.get(adressa)
          .then(function (response) {
            if (that._ucitano === true) {
              that.setState({
                dolasci: response.data.filter(letovi => letovi.tip === "IA"),
                odlasci: response.data.filter(letovi => letovi.tip === "ID"),
                ucitano: true,
              //  jezik: huha
              });
            }

              that.trigger();

          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });

     }



     componentDidUpdate(prevProps) {
       if(this.props.ijezik !== prevProps.ijezik && this.state.ucitano === true) {
        const duadressa = localStorage.getItem("jezik") === '0' ? 'https://konsoft.rs/2806/dml.php' : 'https://konsoft.rs/2806/dml-e.php';
        const duthat = this;
        //const huha = this.state.jezik;
          //    axios.get('http://konsoft.rs/2806/dml.php')
              axios.get(duadressa)
              .then(function (response) {
                if (duthat._ucitano === true) {
                  duthat.setState({
                    dolasci: response.data.filter(letovi => letovi.tip === "IA"),
                    odlasci: response.data.filter(letovi => letovi.tip === "ID")
                  //  jezik: huha
                  });
                }

                  //duthat.trigger();

              })
              .catch(function (error) {
                // handle error
                console.log(error);
              });


       }
     }

     trigger() {
       if(this.state.ucitano === true) {
         this._intervalId = setInterval(() => {

           const tadressa = this.state.jezik === 0 ? 'https://konsoft.rs/2806/dml.php' : 'https://konsoft.rs/2806/dml-e.php';
           const that = this;
                 //axios.get('http://konsoft.rs/2806/dml.php')
                 axios.get(tadressa)
                 .then(function (response) {
                   if (that._ucitano === true) {
                     that.setState({
                       dolasci: response.data.filter(letovi => letovi.tip === "IA"),
                       odlasci: response.data.filter(letovi => letovi.tip === "ID")
                     });
                   }

                 })
                 .catch(function (error) {
                   // handle error
                   console.log(error);
                 });

         }, 30000);
       }
     }

     componentWillUnmount(){
         this._ucitano = false;
        clearInterval(this._intervalId);
        this.setState({
          ucitano: false
        });
      }


  onChange = activeKey => {
    this.setState({ activeKey });
  };


  showModal = () => {
    this.setState({
      mvisible: true,
    });
  };

  handleCancel = () => {
    this.setState({ mvisible: false });
  };


  render() {

const operations = <div><span className='avionaero'>{this.state.activeKey === '1' ? <Dolasci /> : <Odlasci />}</span><span className={this.props.imob === true ? 'naslovaeromob':'naslovaero'}>
  {this.state.jezik === 0 ? "Aerodrom Nikola Tesla" : "Nikola Tesla Airport"}</span></div>;


const columns = [
  {
    title: 'Vreme',
    dataIndex: 'st',
    key: 'id',
    render: text => <span>{text}</span>,
  },
  {
    title: 'Let',
    dataIndex: 'let',
    key: 'let',
    render: letenje => <span className='boldlet'>{letenje}</span>
  },
  {
    title: 'Iz',
    dataIndex: 'dest',
    key: 'dest',
  },
  {
    title: 'Status',
    key: 'remark',
    dataIndex: 'remark',
    render: remark => (
      <span className='aerosemafor'>
        {
        (remark.startsWith('Izlaz otv') || remark.startsWith('Ukrca') || remark.startsWith('Check') || remark.startsWith('Sleteo')) ?
        <><Badge status="success" /> {remark}</>
        :
        (remark.startsWith('Otkazan') || remark.startsWith('Preusmeren')) ?
        <><Badge status="error" /> {remark}</>
        :
        (remark.startsWith('Pred pol') || remark.startsWith('Poslednji') || remark.startsWith('Izlaz zatv')) ?
        <><Badge status="processing" /> {remark}</>
        :
        (remark.startsWith('Na vreme') || remark.startsWith('Stigao') || remark.startsWith('Odleteo')) ?
        <><Badge status="default" /> {remark}</>
        :
        <><Badge status="warning" /> {remark}</>
        }
      </span>
    ),


  },
];

const columns2 = [
  {
    title: 'Vreme',
    dataIndex: 'st',
    key: 'id',
    render: text => <span>{text}</span>,
  },
  {
    title: 'Let',
    dataIndex: 'let',
    key: 'let',
    render: letenje => <span className='boldlet'>{letenje}</span>
  },
  {
    title: 'Za',
    dataIndex: 'dest',
    key: 'dest',
  },
  {
    title: 'Status',
    key: 'remark',
    dataIndex: 'remark',
    render: remark => (
      <span className='aerosemafor'>
        {
        (remark.startsWith('Izlaz otv') || remark.startsWith('Check') || remark.startsWith('Sleteo')) ?
        <><Badge status="success" /> {remark}</>
        :
        (remark.startsWith('Otkazan') || remark.startsWith('Preusmeren')) ?
        <><Badge status="error" /> {remark}</>
        :
        (remark.startsWith('Pred pol') || remark.startsWith('Poslednji') || remark.startsWith('Izlaz zatv') || remark.startsWith('Ukrca')) ?
        <><Badge status="processing" /> {remark}</>
        :
        (remark.startsWith('Na vreme') || remark.startsWith('Stigao') || remark.startsWith('Odleteo')) ?
        <><Badge status="default" /> {remark}</>
        :
        <><Badge status="warning" /> {remark}</>
        }
      </span>
    ),
  },
];


// *****************************

const columnse = [
  {
    title: 'Time',
    dataIndex: 'st',
    key: 'id',
    render: text => <span>{text}</span>,
  },
  {
    title: 'Flight No.',
    dataIndex: 'let',
    key: 'let',
    render: letenje => <span className='boldlet'>{letenje}</span>
  },
  {
    title: 'From',
    dataIndex: 'dest',
    key: 'dest',
  },
  {
    title: 'Status',
    key: 'remark',
    dataIndex: 'remark',
    render: remark => (
      <span className='aerosemafor'>
        {
        (remark.startsWith('Gate op') || remark.startsWith('Boarding') || remark.startsWith('Check') || remark.startsWith('Landed')) ?
        <><Badge status="success" /> {remark}</>
        :
        (remark.startsWith('Cancelled') || remark.startsWith('Diverted')) ?
        <><Badge status="error" /> {remark}</>
        :
        (remark.startsWith('Taxi') || remark.startsWith('Last call') || remark.startsWith('Gate closed')) ?
        <><Badge status="processing" /> {remark}</>
        :
        (remark.startsWith('On time') || remark.startsWith('Arrived') || remark.startsWith('Departed')) ?
        <><Badge status="default" /> {remark}</>
        :
        <><Badge status="warning" /> {remark}</>
        }
      </span>
    ),


  },
];

const columns2e = [
  {
    title: 'Time',
    dataIndex: 'st',
    key: 'id',
    render: text => <span>{text}</span>,
  },
  {
    title: 'Flight No.',
    dataIndex: 'let',
    key: 'let',
    render: letenje => <span className='boldlet'>{letenje}</span>
  },
  {
    title: 'To',
    dataIndex: 'dest',
    key: 'dest',
  },
  {
    title: 'Status',
    key: 'remark',
    dataIndex: 'remark',
    render: remark => (
      <span className='aerosemafor'>
        {
        (remark.startsWith('Gate open') || remark.startsWith('Check') || remark.startsWith('Landed')) ?
        <><Badge status="success" /> {remark}</>
        :
        (remark.startsWith('Cancelled') || remark.startsWith('Diverted')) ?
        <><Badge status="error" /> {remark}</>
        :
        (remark.startsWith('Taxi') || remark.startsWith('Last call') || remark.startsWith('Gate closed') || remark.startsWith('Boarding')) ?
        <><Badge status="processing" /> {remark}</>
        :
        (remark.startsWith('On time') || remark.startsWith('Arrived') || remark.startsWith('Departed')) ?
        <><Badge status="default" /> {remark}</>
        :
        <><Badge status="warning" /> {remark}</>
        }
      </span>
    ),
  },
];


    return (
<div className=''>
      <div className={this.props.imob === true ? 'aeromob' : 'aero'}>
            <Tabs className='aerotabs' tabBarExtraContent={operations} onChange={this.onChange} activeKey={this.state.activeKey} >
            <TabPane tab={this.state.jezik === 0 ? "Dolasci" : "Arrivals"} key="1" className='tabdol'>
                <Table rowClassName={this.props.imob === true ? 'aeroredmob':'aerored'} loading={!this.state.ucitano} columns={this.state.jezik === 0 ? columns : columnse} dataSource={this.state.ucitano === true ? this.state.dolasci : []} className={this.props.imob === true ? 'tabelaaeromob':'tabelaaero'} size='small' pagination={false} />
            </TabPane>
            <TabPane tab={this.state.jezik === 0 ? "Odlasci" : "Departures"} key="2">
                <span>
                <Table rowClassName={this.props.imob === true ? 'aeroredmob':'aerored'} loading={!this.state.ucitano} columns={this.state.jezik === 0 ? columns2 : columns2e} dataSource={this.state.ucitano === true ? this.state.odlasci : []} className={this.props.imob === true ? 'tabelaaeromob':'tabelaaero'} size='small' pagination={false} />
                </span>
            </TabPane>
            </Tabs>
            {
            this.state.jezik === 0 ?
            <div className={this.props.imob === true ? 'footeraeromob':'footeraero'}>{this.state.ucitano === true ? <>aerodrom uživo - podaci od {this.state.dolasci[0].upt}</> : ''}</div>
            :
            <div className={this.props.imob === true ? 'footeraeromob':'footeraero'}>{this.state.ucitano === true ? <>Airport Live - data from {this.state.dolasci[0].upt} local time</> : ''}</div>
            }
      </div>


      { this.state.jezik === 0 ?
          <div className='kakoradi'  onClick={this.showModal}>Kako radi ova aplikacija?</div>
          :
          <div className='kakoradi'  onClick={this.showModal}>How does this App work?</div>
      }

      <Modal
              visible={this.state.mvisible}
              title={this.state.jezik === 0 ? "Aerodrom Nikola Tesla - uživo" : "Nikola Tesla Airport - Live"}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={[
              <Button key="back" onClick={this.handleCancel}>
              {
              this.state.jezik === 0 ?
              'Zatvori'
              :
              'Close'
              }
              </Button>,
          ]}
          >

          {
          this.state.jezik === 0 ?
          <>
          <p>1. Osoblje aerodroma unosi informaciju u svoj sistem. Ta informacija je dostupna na internetu preko servera beg.aero.</p>
          <p>2. Svakih 60 sekundi naš server (konsoft.rs) generiše upit koji šalje serveru beg.aero. Server beg.aero nam vraća XML fajl sa trenutnim stanjem letova.
          Naš skript čita podatke iz dobijenog XML fajla i čuva ih u našoj SQL bazi podataka.</p>
          <p>3. Svakih 30 sekundi naš front-end u vašem browser-u šalje upit ka serveru konsoft.rs (daj mi nove podatke), i dobija ih u formatu JSON. Nakon toga se na donjem plavom polju ispisuje vreme kada su podaci dobijeni.</p>
          <p>4. Ako promena nije bilo - ne dešava se ništa. Ako ima promena - u tabelama odlazaka i dolazaka biće osveženi podaci. Bez ikakvih 'Loading' / 'Refreshing' / 'Učitavam' / 'Osvežavam'.</p>
          <p>P.S.<br />Aplikacija nije 100% "realtime". Iz poštovanja prema tuđim resursima mi šaljemo upite na beg.aero tek svakih 60 sekundi, što znači da informacija u tabelama može da kasni od 1 do 60 sekundi u odnosu na informaciju koja se prikazuje na aerodromu.
          </p>
          </>
          :
          <>
          <p>1. Airport personnel enter data into the information system. The data is available on the web by the beg.aero server.</p>
          <p>2. Every 60 seconds our server (konsoft.rs) generates a request to beg.aero. In response, beg.aero sends XML file with current flight status. Our script reads data from received XML file and stores it in our SQL server.</p>
          <p>3. Every 30 seconds our front-end in your browser generates request to the konsoft.rs server (please provide new data) and receives it in the JSON format. Upon receiving, the time on the blue footer is changing.</p>
          <p>4. If there is no change, nothing will happen. If data is changed, the timetable will be silently refreshed without any 'Loading' or 'Refreshing' messages.</p>
          <p>P.S.<br />This App is not 100% realtime. Out of respect for airport's resources, we send requests to beg.aero every 60 seconds. It means that our timetable data could be late from 1 to 60 seconds.
          </p>
          </>

          }


      </Modal>
        </div>
      );
  }
}

export default Aero;

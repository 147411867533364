import React, { Component } from 'react';
import { Icon } from 'antd';
import VideoPlayer from '../video/vp.js'
import 'video.js/dist/video-js.css';






class Show4 extends Component {

  render() {

    var videoJsOptions;


    this.props.ijezik === 0 ?
        videoJsOptions = {
          autoplay: false,
          controls: true,
          sources: [{
            src: "https://konsoft.rs/video/mrx.mp4",
            type: "video/mp4"
          }]
        }
    :
        videoJsOptions = {
          autoplay: false,
          controls: true,
          sources: [{
            src: "https://konsoft.rs/video/mrx-e.mp4",
            type: "video/mp4"
          }]
        }




    return (
        <div className={this.props.imob === true ? 'sredinamob' : 'sredina'}>

{
    this.props.ijezik === 0 ?
        <>
        <div className={this.props.imob === true ? 'prezamob' : 'preza'}>4/5 - OfficeMaster, planiranje i naplata</div>
            <div className={this.props.imob === true ? 'novprezmob' : 'novprez'}>
                    <div className={this.props.imob === true ? 'show-problemmob' : 'show-problem'}>
                    <h4><b>MRX Solutions</b>, Vancouver</h4>
                    <p>
                    OfficeMaster je najpopularniji softver za kiropraktičarske klinike i ordinacije u Kanadi. Koristi ga više od 1200 klijenata. Razvoj softvera je započet pre 18 godina, u vreme kada web aplikacije današnjeg kvaliteta nije bilo moguće napraviti, te je pravljen na "klasičan" način. Mora se instalirati na svaki računar. Angažovani smo da napravimo web aplikaciju na bazi postojećeg softvera, uz korišćenje postojećeg back-enda (Apache Tomcat, eXist-db, XQuery). Na zahtev klijenta aplikacija se razvija u react-u.
                    </p>
                    <p className='pnote'>
                    <Icon type='warning' /> U priloženom videu aplikacija je konektovana na MRX Demo Server (izmišljeni podaci).
                    </p>
                    </div>
                    <div className='za-video'>
                    <div className='show-problem-v'>
                    <VideoPlayer { ...videoJsOptions }  />
                    </div>
                    </div>
            </div>
        </>
        :
        <>
        <div className={this.props.imob === true ? 'prezamob' : 'preza'}>4/5 - OfficeMaster, scheduling and billing</div>
            <div className={this.props.imob === true ? 'novprezmob' : 'novprez'}>
                    <div className={this.props.imob === true ? 'show-problemmob' : 'show-problem'}>
                    <h4><b>MRX Solutions</b>, Vancouver BC</h4>
                    <p>
                    MRX Solutions have developed OfficeMaster program, that has become the most popular software for chiropractic clinics in Canada. It is used by over 1,200 clients. Software development started 18 years ago, at a time when web applications of today's quality could not beachieved, so it was made in a "classic" way. It has to be installed on every computer. Regardless, we are committed to creating a web application based on existing software and using existing back-end (Apache Tomcat, eXist-db, XQuery). On the request of the client, we are developing application in the react.
                    </p>
                    <p className='pnote'>
                    <Icon type='warning' />  Application is connected to the MRX Demo Server (fake data) in the video.
                    </p>
                    </div>
                    <div className='za-video'>
                    <div className='show-problem-v'>
                    <VideoPlayer { ...videoJsOptions }  />
                    </div>
                    </div>
            </div>
        </>

}


        </div>
      );

}
}
export default Show4;

import React, { Component } from 'react';



class Show0 extends Component {
  /*
  constructor (props) {
  super(props);
}
*/



  render() {
    if (this.props.imob === false) {
    return (
      this.props.ijezik === 0 ?

            <div className='sredina uvu'>
            <span className='beli orbo cvekla'>Naše aplikacije</span>
            <div className='arima orbo cvekla'>Bolje jednom videti, nego sto puta čuti</div>
            <div className='initxt'>Možete da pogledate 5 aplikacija koje smo radili.</div>
            <div className='initxt2'>Svi video fajlovi su montirani <u>bez zvuka</u>.</div>
            <div className='initxt2'>Video možete prebaciti u <u>fullscreen</u>, isto kao što to radite na Youtube-u.</div>
            </div>

    :
            <div className='sredina uvu'>
            <span className='beli orbo cvekla'>Showcase</span>
            <div className='arima orbo cvekla'>A picture is worth a thousand words</div>
            <div className='initxt'>Please have a look at 5 applications we have been working on.</div>
            <div className='initxt2'>All video files are edited <u>without sound</u>.</div>
            <div className='initxt2'>You can switch the video to <u>fullscreen mode</u>.</div>
            </div>
      )

    }

    return (
      this.props.ijezik === 0 ?

            <div className='sredina uvumob'>
            <span className='belimob orbo cvekla'>Naše aplikacije</span>
            <div className='arimamob orbo cvekla'>Bolje jednom videti, nego sto puta čuti</div>
            <div className='initxtmob'>Možete da pogledate 5 aplikacija koje smo radili.
            Svi video fajlovi su montirani <u>bez zvuka</u>.
            Video možete prebaciti u <u>fullscreen</u>, isto kao što to radite na Youtube-u.</div>
            </div>

    :
            <div className='sredina uvumob'>
            <span className='belimob orbo cvekla'>Showcase</span>
            <div className='arimamob orbo cvekla'>A picture is worth a thousand words</div>
            <div className='initxtmob'>Please have a look at 5 applications we have been working on.
            All video files are edited <u>without sound</u>.
            You can switch the video to <u>fullscreen mode</u>.</div>
            </div>
      );


    }
}

export default Show0;

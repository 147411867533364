import React, { Component } from 'react';
import { Icon } from 'antd';
import VideoPlayer from '../video/vp.js'
import 'video.js/dist/video-js.css';






class Show3 extends Component {

  render() {

    var videoJsOptions;


    this.props.ijezik === 0 ?
        videoJsOptions = {
          autoplay: false,
          controls: true,
          sources: [{
            src: "https://konsoft.rs/video/rn.mp4",
            type: "video/mp4"
          }]
        }
    :
        videoJsOptions = {
          autoplay: false,
          controls: true,
          sources: [{
            src: "https://konsoft.rs/video/rn-e.mp4",
            type: "video/mp4"
          }]
        }




    return (
        <div className={this.props.imob === true ? 'sredinamob' : 'sredina'}>

{
  this.props.ijezik === 0 ?
          <>
          <div className={this.props.imob === true ? 'prezamob' : 'preza'}>3/5 - Radni nalozi</div>
          <div className={this.props.imob === true ? 'novprezmob' : 'novprez'}>
                  <div className={this.props.imob === true ? 'show-problemmob' : 'show-problem'}>
                  <h4><b>Buck Lighting</b>, Beograd</h4>
                  <p>
                  Osnovni podaci o radnim nalozima se čuvaju u kompanijskom ERP sistemu, drugi relevantni za proizvodnju dokumenti se čuvaju na fajl serveru, tehnologija proizvodnje ima svoj server sa web aplikacijom. Ideja je bila - olakšati rad zaposlenima u proizvodnji, omogućiti im da na jednom mestu u jednostavnom i preglednom interfejsu brzo pronađu sve informacije koje su im potrebne za rad. Web server sa našom aplikacijom povlači podatke iz MS SQL baze koju koristi Navision, proverava na serveru za tehnologiju da li postoji napisana tehnologija za dati artikal, i ako postoji - dodaje link ka njoj.
                  </p>
                  <p className='pnote'>
                  <Icon type='warning' /> U priloženom videu aplikacija je konektovana na bazu sa izmišljenim podacima.
                  </p>
                  </div>
                  <div className='za-video'>
                  <div className='show-problem-v'>
                  <VideoPlayer { ...videoJsOptions }  />
                  </div>
                  </div>
          </div>
        </>
  :
        <>
        <div className={this.props.imob === true ? 'prezamob' : 'preza'}>3/5 - Work orders Tracking System</div>
        <div className={this.props.imob === true ? 'novprezmob' : 'novprez'}>
                <div className={this.props.imob === true ? 'show-problemmob' : 'show-problem'}>
                <h4><b>Buck Lighting</b>, Belgrade</h4>
                <p>
                The basic information about company's work orders is stored in the ERP system. Other relevant documents are stored on the file server. The technology team has its own server with the web application. Our aim was to make it easier for employees in the production section  to quickly find all the information they need in a simple and user friendly interface. The web server with our application retrieves data from the MS SQL database used by Navision, checks the server for written technology for the given product number, and adds a link to it.
                </p>
                <p className='pnote'>
                <Icon type='warning' />  Application is connected to the database with the fake data in the video.
                </p>
                </div>
                <div className='za-video'>
                <div className='show-problem-v'>
                <VideoPlayer { ...videoJsOptions }  />
                </div>
                </div>
        </div>
      </>

}

        </div>
      );
  }
}

export default Show3;

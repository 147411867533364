import React from 'react';
import ScrollSnap from "scroll-snap";
import { notification, Icon, Spin, Button, Drawer, message } from 'antd';

import './App.css';
import 'antd/dist/antd.css';

import { ReactComponent as Logo } from './logo_b.svg';
import { ReactComponent as Meni } from './menii.svg';
import { ReactComponent as Rotate } from './img/rot.svg';
import { ReactComponent as Expand } from './img/exp.svg';

import Aero from './Aero.jsx';
import TestQ from './TestQ.jsx';
import Guda from './Guda.jsx';
import MyWay from './MyWay.jsx';




const snapConfig = {
  scrollSnapDestination: "0% 100%",
  scrollTimeout: 30,
  scrollTime: 300
};

message.config({
  top: 60,
  duration: 4,
});


class App extends React.Component {
  constructor (props) {
  super(props);
  this.state = {
    ucitao: 0,
    jezik: 0,
    dugme1: true,
    dugme2: true,
    drawer: false,
    porukica: '',
    mob: null,
    mobdet: null,
    reza: ''
  };
}
  container = React.createRef();

    bindScrollSnap() {
      function callback() {
        // optional callback
      }
      const element = this.container.current;
      const snapObject = new ScrollSnap(element, snapConfig);
      snapObject.bind(callback);
    }

    componentDidMount() {

    const detektor = 'ontouchstart' in document.documentElement && /iphone|ipod|ipad|android|mobi|mobile|iemobile|iphone|ipad|ipod|blackberry|bada/i.test(navigator.userAgent);

    if (localStorage.getItem("mob") === null) {
      this.setState({
        mob: detektor,
        mobdet: detektor
      });
    } else {
        this.setState({
          mobdet: detektor,
          mob: JSON.parse(localStorage.getItem("mob")
        )});
    }



    if (window.innerWidth < 1360) {
        this.setState({ mob: true});
    }

      // ******************************************************************************
      this.bindScrollSnap();
      this.setState({ ucitao: 1 });


      if (localStorage.getItem("jezik") === null) {
        localStorage.setItem('jezik', 0);
      } else {
        var du1 = Number(localStorage.getItem("jezik")) === 0 ? true : false;
        var du2 = Number(localStorage.getItem("jezik")) === 1 ? true : false;
        const bropor = Number(localStorage.getItem("jezik")) === 0 ?
        'Program u kojem otvarate web sajtove. Google Chrome, Microsoft Edge, Apple Safari, Mozilla Firefox, Opera, Internet Explorer... i drugi.'
        :
        'A web browser, or simply "browser," is an application used to access and view websites. Commonly used web browsers are Google Chrome, Microsoft Edge, Apple Safari, Mozilla Firefox, Opera, Internet Explorer and others.';
        this.setState({
          jezik: Number(localStorage.getItem("jezik")),
          dugme1: du1,
          dugme2: du2,
          porukica: bropor
        });
      }
      window.addEventListener("resize", this.kolikiJe);
    }

    componentWillMount() {
    this.kolikiJe();
    }

    componentWillUnmount() {
    window.removeEventListener("resize", this.kolikiJe);
    }

    kolikiJe = () => {
      let lakat = Math.max(window.innerWidth, window.innerHeight)
      let zarezu = window.innerWidth+' x '+window.innerHeight;
      this.setState({reza:zarezu});
      if (lakat < 1360) {
        this.setState({mob: true});
      } else {
        this.setState({mob: false});
      }
    };

    menjajJezik = (e) => {


      if (e.target.name === 's') {
        this.setState({ dugme1: true, dugme2: false, jezik: 0, porukica: 'Program u kojem otvarate web sajtove. Google Chrome, Microsoft Edge, Apple Safari, Mozilla Firefox, Opera, Internet Explorer... i drugi.' });
        localStorage.setItem('jezik', 0);
      } else if (e.target.name === 'e') {
        this.setState({ dugme1: false, dugme2: true, jezik: 1, porukica: 'A web browser, or simply "browser," is an application used to access and view websites. Commonly used web browsers are Google Chrome, Microsoft Edge, Apple Safari, Mozilla Firefox, Opera, Internet Explorer and others.' });
        localStorage.setItem('jezik', 1);
      }

    }

    menjajPrikaz = (e) => {
      if (window.innerWidth < 1360) {
        if (this.state.jezik === 0) {
          document.activeElement.blur();
          message.warning('Za desktop prikaz je neophodno najmanje 1366 x 768 piksela');
        } else {
          document.activeElement.blur();
          message.warning('Low resolution. Desktop site need at least 1366x768 px');
        }

      } else {
          if (e.currentTarget.attributes.name.nodeValue === 'd') {
            this.setState({ mob: false });
            localStorage.setItem('mob', false);
          } else if (e.currentTarget.attributes.name.nodeValue === 'm') {
            this.setState({ mob: true });
            localStorage.setItem('mob', true);
          }
      }
    }



    showDrawer = () => {
      this.setState({
        drawer: true,
      });
    };

    onClose = () => {
      this.setState({
        drawer: false,
      });
    };


    openNotification = () => {
          notification.open({
            top: 60,
            duration: 8,
            message: 'Browser:',
            description:
                this.state.porukica
          });
    };



  render() {
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// Pocetak mobilne verzije @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

if (this.state.mob === true) {
  return (

    <>
    <div id="container" ref={this.container}>



      <div id="Naslovna" className='child chmob'>
            <div className='parolamob'>
{ this.state.jezik === 0?
            <>
            <span className='belimob'>Mi rešavamo probleme</span>
            <div className='arimamob'>tamo gde ih je moguće rešiti pomoću web aplikacija</div>
            <div className='initxtmob'><b>Više od decenije iskustva u izradi web aplikacija: </b>
              upravljanje dokumentima, upravljanje obavezama, webmail, kontrola pristupa i radnog vremena, zakazivanje pregleda i naplata, upravljanje tehnologijama, praćenje radnih naloga, planiranje proizvodnje...
            </div>
            </>
            :
            <>
            <span className='belimob'>We solve problems</span>
            <div className='arimamob'>when they can be solved by web applications</div>
            <div className='initxtmob deli'><b>Over a decade of experience in web apps: </b>
               document management, task management, webmail, attendance and tracking, scheduling and billing, technology management, work orders tracking, production planning...
            </div>
            </>
}
            </div>
      </div>

      <div className='child chmob'>
        <div className='parolamob'>
            <Aero imob={this.state.mob} ijezik={this.state.jezik} />
          </div>
      </div>



      <div id='Aplikacije' className='child chmob'>

            <div className='parolamob'>

{

            this.state.jezik === 0 ?
            <>
            <span className='belimob orbo'>Web aplikacije</span>
            <div className='arimamob orbo'>npr: google, facebook, gmail, web aplikacija vaše banke itd.</div>
            <div className='initxtmob'>Web aplikacija je program (softver) koji radi preko interneta ili neke lokalne mreže.
            Ona se ne instalira na računare, već joj pristupate iz <span className='txtlink' onClick={this.openNotification} >browser</span>-a.
            Nakon unošenja adrese aplikacije (npr. google.com), server će vam poslati deo programa koji ćete vi videti i sa kojim ćete raditi. Taj deo programa se zove <b>front-end</b>.
            Druge komponente programa, neophodne za rad, nalaze se na serveru. One se zovu <b>back-end</b>.</div>
            </>
            :
            <>
            <span className='belimob orbo'>Web applications</span>
            <div className='arimamob orbo'>e.g. google, facebook, gmail, airbnb, your bank's web app etc.</div>
            <div className='initxtmob'>Web app is a computer program that you can run in a <span className='txtlink' onClick={this.openNotification} >web browser</span>. Web apps require no installation at all on your local computer.
            After entering the address of the application (for example, google.com), the server will send you a fragment of the program, that you can work with. This part of the program is called the <b>front-end</b>.
            Other necessary components of the program are located on the server and are called the <b>back-end</b>.</div>
            </>




}

            </div>
      </div>


      <div className='child chmob'>
        <div className='parolamob'>
        <Guda imob={this.state.mob} ijezik={this.state.jezik} />
{

      this.state.jezik === 0 ?
        <div className='potpis-guda'>Neke web tehnologije koje se koriste za izradu aplikacija</div>
      :
        <div className='potpis-guda'>Some web technologies used to create applications</div>
}
        </div>
      </div>




      <div id='Showcase' className='child chmob childshow'>

        <MyWay ijezik={this.state.jezik} imob={this.state.mob}/>

      </div>

      <div id='Kontakt' className='child chmob'>
        <div className='parolamob'>
            <span className='belimob kaktus'>{this.state.jezik === 0 ? 'Kontakt' : 'Contact'}</span>
            <div className='arimamob kaktus'><Icon type='mail' /> office@konsoft.rs </div>
            <div className='arimamob kaktus'><Icon type='mobile' />+381 63 256484 </div>
            <div className='initxtmob'>© KonSoft MMXIX</div>
        </div>

      </div>


      <div className='child chmob'>
        <div className='parolamob'>
          <TestQ ijezik={this.state.jezik} />
{

          this.state.jezik === 0 ?
          <div className='potpistest'>Izaberite figuru koja logično nastavlja gornji niz</div>
          :
          <div className='potpistest'>Select a shape that logically continues the top row.</div>
}
      </div>
    </div>


    </div>
    <div className='shapka'>
        <Logo className='belilogo' alt="Logo" />




        <span className='jeziki'>
            <Button ghost name='s' type="" size='small' disabled={this.state.dugme1} className={this.state.dugme1 === true ? 'iskljuceno' : ''} onClick={this.menjajJezik}>Sr</Button>
            <Button ghost name='e' size='small' disabled={this.state.dugme2} className={this.state.dugme2 === true ? 'iskljuceno' : ''} onClick={this.menjajJezik}>En</Button>
            <span className='jezmob' />
{ this.state.mob === true ?
            <>

            <span className='desmob' name='d' onClick={this.menjajPrikaz}><Icon type='desktop' style={{ color: '#fff', fontSize: '20px' }} /></span>

            <span className='desmobdis' name='m' onClick={this.menjajPrikaz}><Icon type='mobile' style={{ color: '#aaa', cursor: 'none', fontSize: '20px' }} /></span>
            </>
            :
            <>
            <span className='desmobdis' name='d' onClick={this.menjajPrikaz}><Icon type='desktop' style={{ color: '#aaa', cursor: 'none', fontSize: '20px' }} /></span>

            <span className='desmob' name='m' onClick={this.menjajPrikaz}><Icon type='mobile' style={{ color: '#fff', fontSize: '20px' }} /></span>

            </>
}

        </span>
        <Meni className='belimeni' alt="Menu" onClick={this.showDrawer}/>

          <Drawer
            title={this.state.jezik === 0 ? 'Meni:' : 'Menu:'}
            placement="right"
            closable={true}
            onClose={this.onClose}
            visible={this.state.drawer}
            className='nadshapka'
          >
{

            this.state.jezik === 0 ?
              <div className='fleko'>
                  <a className='a-meni' href="#Naslovna" onClick={this.onClose}>Naslovna strana</a>
                  <a className='a-meni' href="#Aplikacije" onClick={this.onClose}>Šta su to web aplikacije?</a>
                  <a className='a-meni' href="#Showcase" onClick={this.onClose}>Naše web aplikacije</a>
                  <a className='a-meni' href="#Kontakt" onClick={this.onClose}>Kontakt</a>
              </div>
            :
              <div className='fleko'>
                  <a className='a-meni' href="#Naslovna" onClick={this.onClose}>Home page</a>
                  <a className='a-meni' href="#Aplikacije" onClick={this.onClose}>What is a Web App?</a>
                  <a className='a-meni' href="#Showcase" onClick={this.onClose}>Our showcase</a>
                  <a className='a-meni' href="#Kontakt" onClick={this.onClose}>Contact</a>
              </div>
}
         </Drawer>
    </div>

        <div className='mikro'>
            <Logo className='belilogo' alt="Logo" />
            <span className='lowres'>Low Resolution</span>
        </div>


        {this.state.mobdet === true ?
        <div className='tesno'>
            <Rotate className='tesnosvg' alt="Please rotate" />
        </div>
        :
        <div className='tesno'>
            <Expand className='tesnosvg' alt="Please expand" />
        </div>
        }


    { this.state.ucitao === 0 && <div className='loader'><Spin size="large" /><span>Malo strpljenja. Brzo će.</span></div> }
    </>

  )}
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// Kraj mobilne verzije @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  return (
    <>
    <div id="container" ref={this.container}>
      <div id="Naslovna" className='child'>
          <div className='ilustracija'>

                <Aero ijezik={this.state.jezik} />

          </div>
            <div className='parola'>
{

            this.state.jezik === 0?
            <>
            <span className='beli'>Mi rešavamo probleme</span>
            <div className='arima'>tamo gde ih je moguće rešiti pomoću web aplikacija</div>
            <div className='initxt'>Više od decenije iskustva u izradi web aplikacija
            <ul className="nabrajanje">
              <li>upravljanje dokumentima,</li>
              <li>upravljanje obavezama,</li>
              <li>webmail,</li>
              <li>kontrola pristupa i radnog vremena,</li>
              <li>zakazivanje pregleda i naplata,</li>
              <li>upravljanje tehnologijama,</li>
              <li>praćenje radnih naloga,</li>
              <li>planiranje proizvodnje...</li>
            </ul>
            </div>
            </>
            :
            <>
            <span className='beli'>We solve problems</span>
            <div className='arima'>when they can be solved by web applications</div>
            <div className='initxt'>Over a decade of experience in web apps:
            <ul className="nabrajanje">
              <li>document management,</li>
              <li>task management,</li>
              <li>webmail,</li>
              <li>attendance and tracking,</li>
              <li>scheduling and billing,</li>
              <li>technology management,</li>
              <li>work orders tracking,</li>
              <li>production planning...</li>
            </ul>
            </div>
            </>
}

            </div>
      </div>
      <div id='Aplikacije' className='child'>
            <div className='ilustracija'>
              <Guda ijezik={this.state.jezik} imob={this.state.mob} />
{

            this.state.jezik === 0 ?
              <div className='potpis-guda'>Neke web tehnologije koje se koriste za izradu aplikacija</div>
            :
              <div className='potpis-guda'>Some web technologies used to create applications</div>
}
            </div>
            <div className='parola'>

{

            this.state.jezik === 0 ?
            <>
            <span className='beli orbo'>Web aplikacije</span>
            <div className='arima orbo'>npr: google, facebook, gmail, airbnb, web aplikacija vaše banke itd.</div>
            <div className='initxt'>Web aplikacija je program (softver) koji radi preko interneta ili neke lokalne mreže.
            Ona se ne instalira na računare, već joj pristupate iz <span className='txtlink' onClick={this.openNotification} >browser</span>-a.</div>
            <div className='initxt2'>Nakon unošenja adrese aplikacije (npr. google.com), server će vam poslati deo programa koji ćete vi videti i sa kojim ćete raditi. Taj deo programa se zove <b>front-end</b>.</div>
            <div className='initxt2'>Druge komponente programa, neophodne za rad, nalaze se na serveru. One se zovu <b>back-end</b>.</div>
            </>
            :
            <>
            <span className='beli orbo'>Web applications</span>
            <div className='arima orbo'>e.g. google, facebook, gmail, airbnb, your bank's web app etc.</div>
            <div className='initxt'>Web app is a computer program that you can run in a <span className='txtlink' onClick={this.openNotification} >web browser</span>. Web apps require no installation at all on your local computer.</div>
            <div className='initxt2'>After entering the address of the application (for example, google.com), the server will send you a fragment of the program, that you can work with. This part of the program is called the <b>front-end</b>.</div>
            <div className='initxt2'>Other necessary components of the program are located on the server and are called the <b>back-end</b>.</div>
            </>
}

            </div>
      </div>

      <div id='Showcase' className='child childshow'>
        <MyWay ijezik={this.state.jezik} imob={this.state.mob} />
      </div>

      <div id='Kontakt' className='child'>

        <div className='ilustracija'>
            <TestQ ijezik={this.state.jezik} />
{

            this.state.jezik === 0 ?
            <div className='potpistest'>Izaberite figuru koja logično nastavlja gornji niz</div>
            :
            <div className='potpistest'>Select a shape that logically continues the top row.</div>
}
        </div>

        <div className='parola'>
            <span className='beli kaktus'>{this.state.jezik === 0 ? 'Kontakt' : 'Contact'}</span>
            <div className='arima kaktus'><Icon type='mail' /> office@konsoft.rs </div>
            <div className='arima kaktus'><Icon type='mobile' />+381 63 256484 </div>
            <div className='initxt'>© KonSoft MMXIX</div>
        </div>

      </div>


    </div>
    <div className='shapka'>
        <Logo className='belilogo' alt="Logo" />




        <span className='jeziki'>
            <Button ghost name='s' type="" size='small' disabled={this.state.dugme1} className={this.state.dugme1 === true ? 'iskljuceno' : ''} onClick={this.menjajJezik}>Sr</Button>
            <Button ghost name='e' size='small' disabled={this.state.dugme2} className={this.state.dugme2 === true ? 'iskljuceno' : ''} onClick={this.menjajJezik}>En</Button>
            <span className='jezmob' />
{ this.state.mob === true ?
            <>

            <span className='desmob' name='d' onClick={this.menjajPrikaz}><Icon type='desktop' style={{ color: '#fff', fontSize: '20px' }} /></span>

            <span className='desmobdis' name='m' onClick={this.menjajPrikaz}><Icon type='mobile' style={{ color: '#aaa', cursor: 'none', fontSize: '20px' }} /></span>
            </>
            :
            <>
            <span className='desmobdis' name='d' onClick={this.menjajPrikaz}><Icon type='desktop' style={{ color: '#aaa', cursor: 'none', fontSize: '20px' }} /></span>

            <span className='desmob' name='m' onClick={this.menjajPrikaz}><Icon type='mobile' style={{ color: '#fff', fontSize: '20px' }} /></span>

            </>
}

        </span>
        <Meni className='belimeni' alt="Menu" onClick={this.showDrawer}/>

          <Drawer
            title={this.state.jezik === 0 ? 'Meni:' : 'Menu:'}
            placement="right"
            closable={true}
            onClose={this.onClose}
            visible={this.state.drawer}
            className='nadshapka'
          >
{

            this.state.jezik === 0 ?
              <div className='fleko'>
                  <a className='a-meni' href="#Naslovna" onClick={this.onClose}>Naslovna strana</a>
                  <a className='a-meni' href="#Aplikacije" onClick={this.onClose}>Šta su to web aplikacije?</a>
                  <a className='a-meni' href="#Showcase" onClick={this.onClose}>Naše web aplikacije</a>
                  <a className='a-meni' href="#Kontakt" onClick={this.onClose}>Kontakt</a>
              </div>
            :
              <div className='fleko'>
                  <a className='a-meni' href="#Naslovna" onClick={this.onClose}>Home page</a>
                  <a className='a-meni' href="#Aplikacije" onClick={this.onClose}>What is a Web App?</a>
                  <a className='a-meni' href="#Showcase" onClick={this.onClose}>Our showcase</a>
                  <a className='a-meni' href="#Kontakt" onClick={this.onClose}>Contact</a>
              </div>
}
         </Drawer>
    </div>



<div className='mikro'>
    <Logo className='belilogo' alt="Logo" />
    <span className='lowres'>Low Resolution</span>
</div>

{this.state.mobdet === true ?
<div className='tesno'>
    <Rotate className='tesnosvg' alt="Please rotate" />
</div>
:
<div className='tesno'>
    <Expand className='tesnosvg' alt="Please expand" />
</div>
}

    { this.state.ucitao === 0 && <div className='loader'><Spin size="large" /><span>Malo strpljenja. Brzo će.</span></div> }
    </>





  );
  }
}

export default App;
